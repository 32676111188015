import gql from 'graphql-tag'
import recruterFragment from './RecruterFragment'
export const CONTENT_RECRUTER_ID_QUERY = gql `
  query getContentRecruterById($_id: ID){
    getContentRecruterById(_id: $_id){
      ...${recruterFragment}
    }
  }
`
export const CONTENT_RECRUTER_QUERY = gql `
  query getContentRecruter{
    getContentRecruter{
      ...${recruterFragment}
    }
  }
`