<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="750">
      <v-card elevation="2" class="pb-5">
        <v-container>
          <v-card-title class="justify-center">{{
            textCard.title
          }}</v-card-title>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-text-field
                v-model="content.title"
                :counter="1000"
                :rules="titleRules"
                label="Titulo"
                required
                outlined
                prepend-inner-icon="mdi-format-clear"
              ></v-text-field>

              <vue-editor v-model="content.description"></vue-editor>

              <v-radio-group v-model="content.position" row
                >Posição:
                <v-radio
                  label="Topo"
                  :value="content.position === 'top' ? content.position : 'top'"
                  checked="true"
                ></v-radio>
                <v-radio
                  label="Lista"
                  :value="
                    content.position === 'list' ? content.position : 'list'
                  "
                ></v-radio>
                <v-radio
                  label="COVID List"
                  :value="
                    content.position === 'covid' ? content.position : 'covid'
                  "
                ></v-radio>
                <v-radio
                  label="FAQ"
                  :value="content.position === 'faq' ? content.position : 'faq'"
                ></v-radio>
              </v-radio-group>
              <v-btn
                class="ma-2"
                outlined
                color="indigo"
                v-if="btnImg"
                @click="
                  img = !img;
                  btnImg = !btnImg;
                "
              >
                Alterar Imagem
              </v-btn>

              <croppa v-model="croppa" style="display: none" ref="croppa">
              </croppa>

              <v-btn
                color="primary"
                class="ma-2 white--text"
                v-if="img"
                fab
                @click="$refs.croppa.chooseFile()"
              >
                <v-icon dark>
                  mdi-cloud-upload
                </v-icon>
              </v-btn>
              <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
                <v-btn text color="primary" class="mr-4" @click="back">
                  Cancelar
                </v-btn>

                <v-btn color="primary" class="mr-4" @click="validate">
                  Salvar
                </v-btn>
              </v-row>
            </v-card-text>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <progressLoad v-if="progressbar"></progressLoad>
    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { SEND_CONTENT_RECRUTER } from "./../graphql/Mutation";
import { CONTENT_RECRUTER_ID_QUERY } from "./../graphql/Query";
import progressLoad from "./../components/progress";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  props: ["id"],
  components: {
    progressLoad,
    VueEditor
  },
  data: () => ({
    dialog: true,
    valid: true,
    croppa: {},
    titleRules: [
      (v) => !!v || "Titulo é obrigatoria",
      (v) =>
        (v && v.length <= 1000) ||
        "O titulo não pode possuir mais de 1000 caracteres",
    ],

    descriptionRules: [
      (v) => !!v || "Descrição é obrigatoria",
      (v) =>
        (v && v.length <= 1000) ||
        "A descrição não pode possuir mais de 1000 caracteres",
    ],
    imageRules: [
      (v) => !!v || "A imagem é obrigatoria",
      (value) =>
        !value || value.size < 2000000 || "A imagem deve ter menos de 1MB!",
    ],

    image: "",
    snackbar: false,
    progressbar: false,
    text: `Conteudo salvos com sucesso`,
    content: {
      title: "",
      description: "",
      image: "",
      position: "list",
    },
    img: false,
    btnImg: true,
  }),
  apollo: {
    getContentRecruterById: {
      query: CONTENT_RECRUTER_ID_QUERY,
      variables() {
        return { _id: this.id };
      },
      fetchPolicy: "no-cache",
      result({ data }) {
        // console.log("dados", this.id);

        if (data.getContentRecruterById) {
          if (this.id != 0) {
            this.content = {
              _id: data.getContentRecruterById._id,
              title: data.getContentRecruterById.title,
              description: data.getContentRecruterById.description,
              position: data.getContentRecruterById.position,
            };
          }
        }
      },
    },
    // $subscribe: {}
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    textCard() {
      return this.content._id
        ? { title: "FORMULÁRIO PARA EDITAR O CONREÚDO DO RECRUTADOR" }
        : { title: "FORMULÁRIO PARA CADASTRAR O CONREÚDO DO RECRUTADOR" };
    },
  },
  methods: {
    async validate() {
      this.$refs.form.validate();  
      if (this.title === "" || this.description === "") {
        return;
      }
      if (this.croppa.hasImage()) {
        this.content.image = this.croppa.generateDataUrl("image/jpeg", 0.8);
      }
      this.progressbar = true;
      await this.$apollo
        .mutate({
          mutation: SEND_CONTENT_RECRUTER,
          variables: this.content,
        })
        .then((data) => {
          this.progressbar = false;
          this.snackbar = true;
          this.dialog = !this.dialog;
          this.$emit("back");
          this.clearContent();
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearContent() {
      this.content.image = "";
      this.content.title = "";
      this.content.description = "";
      this.content.position = "";
      (this.img = false), (this.btnImg = true);
    },
    back() {
      this.clearContent();
      this.dialog = false;
      this.$emit("back");
    },
  },
  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("dialogSlideAdd", () => {
      // this.id.toString()
      if (this.id) {
        this.clearContent();
      }
      this.dialog = true;
    });
  },
};
</script>
