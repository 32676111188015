import gql from 'graphql-tag'
import recruterFragment from './RecruterFragment'
export const SEND_CONTENT_RECRUTER = gql `

  mutation addContentRecruter($position: String,$image: Upload,$_id:ID,$title: String, $description: String) {
    addContentRecruter(position:$position,image: $image, _id:$_id, title:$title, description:$description){
      ...${recruterFragment}
    }
  }
`
export const DELETE_CONTENT_RECRUTER = gql `
  mutation deleteContentRecruter($id:ID) {
    deleteContentRecruter(id:$id){
      ...${recruterFragment}
    }
  }
`